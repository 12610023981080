@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:"Playfair Display", serif;
  font-weight: 500;
}
.roboto{
  font-family:"Roboto", sans-serif;
}

